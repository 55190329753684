import { Value } from '@sinclair/typebox/value'
import type { Project } from 'tela-api/src/database/schemas/project'
import { selectProjectSchema } from 'tela-api/src/database/schemas/project'
import type { Optional } from '~/utils/type-helpers'

export function useProjects() {
    const log = useLog()
    const { api } = useAPI()
    const notification = useToast()
    const projects = useState<Project[] | null>('projects', () => null)
    const deletingProjectId = useState<string | null>('deleting-project-id', () => null)
    const currentProjectId = useState<string | null>('current-project-id', () => null)
    const availableProjects = computed(() => projects.value?.filter(p => !p.deletedAt) || null)
    const deletedProjects = computed(() => projects.value?.filter(p => p.deletedAt) || null)
    const currentProject = computed(() => projects.value?.find(p => p.id === currentProjectId.value) || null)
    const { currentWorkspace } = useWorkspaces()

    const isFetchingProjects = useState<boolean>('is-fetching-projects', () => false)

    async function fetchProjects() {
        isFetchingProjects.value = true
        const fetchedProjects = await api().project.get({ $query: { workspaceId: currentWorkspace.value?.id } })
        isFetchingProjects.value = false

        if (fetchedProjects.error) {
            notification.add({
                title: 'Failed to fetch projects',
                description: fetchedProjects.error.message,
                color: 'red',
            })
            return
        }

        projects.value = fetchedProjects.data.map(toProject)
        return projects.value
    }

    function getProjectById(projectId: string) {
        return projects.value?.find(project => project.id === projectId)
    }

    async function createProject(title: string) {
        const project: Optional<Project, 'createdAt' | 'deletedAt' | 'updatedAt' | 'id'> = {
            title,
            workspaceId: currentWorkspace.value!.id,
        }

        const createdProject = await api().project.post(project)

        if (createdProject.error)
            throw createdProject.error

        projects.value = [...projects.value!, toProject(createdProject.data)]
        return toProject(createdProject.data)
    }

    async function deleteProject(projectId: string) {
        const deletedProject = await api().project[projectId].delete()

        if (deletedProject.error) {
            notification.add({
                title: 'Failed to delete project',
                description: deletedProject.error.message,
                color: 'red',
            })
            return
        }

        const { prompts, deletePrompt } = usePrompts()
        for (const prompt of prompts.value!) {
            if (prompt.projectId === projectId)
                deletePrompt(prompt)
        }

        projects.value = projects.value!.filter(p => p.id !== projectId)
    }

    // TODO: Restore project by properly removing the deletedAt field
    // function restoreProject(projectId: string) {
    //     projects.value = projects.value.map((p) => {
    //         if (p.id === projectId)
    //             return { ...p, deletedAt: undefined }

    //         return p
    //     })

    //     const { prompts, restorePrompt } = usePrompts()
    //     for (const prompt of prompts.value) {
    //         if (prompt.projectId === projectId)
    //             restorePrompt(prompt)
    //     }
    // }

    async function updateProject(projectId: string, update: Partial<Project>) {
        log.info('Updating project', { projectId, update })

        const idx = projects.value!.findIndex(p => p.id === projectId)
        if (idx === -1)
            throw new Error('Project not found')

        const beforeUpdate = { ...projects.value![idx] }
        projects.value![idx] = { ...projects.value![idx], ...update }

        const updatedProject = await api().project[projectId].patch(update)

        if (updatedProject.error) {
            notification.add({
                title: 'Failed to update project',
                description: updatedProject.error.message,
                color: 'red',
            })
            projects.value![idx] = beforeUpdate
            return
        }

        return updatedProject
    }

    async function initializeProjects() {
        watchEffect(async () => {
            const route = useRoute()
            if (!route.redirectedFrom && route.name === 'projects-id' && availableProjects.value?.some(({ id }) => id === route.params.id as string))
                currentProjectId.value = route.params.id as string

            if (availableProjects.value === null && currentWorkspace.value && !projects.value) {
                isFetchingProjects.value = true
                await fetchProjects()
                isFetchingProjects.value = false
            }
        })
    }

    return {
        projects,
        currentProjectId,
        currentProject,
        createProject,
        deleteProject,
        updateProject,
        availableProjects,
        deletedProjects,
        deletingProjectId,
        getProjectById,
        fetchProjects,

        // restoreProject,

        initializeProjects,
        isFetchingProjects,
    }
}

function toProject(project: any) {
    return Value.Convert(selectProjectSchema, project) as Project
}
